import React, { useCallback, useState } from "react";
import { MacTerminal, Terminal, Browser } from "react-window-ui";
import Highlight, { defaultProps } from "prism-react-renderer";
import copy from "copy-text-to-clipboard";
import stripIndent from "strip-indent";
import "./App.css";
import dragGif from "./img/drag.gif";
import syncGif from "./img/sync.gif";
import invertColorsGif from "./img/invert-colors.gif";
import downloadGif from "./img/download.gif";
import autocompleteGif from "./img/autocomplete.gif";

const Copy = () => {
  const [label, setLabel] = useState("copy");
  const onClick = useCallback(
    (e) => {
      const { target } = e;
      const { previousElementSibling } = target;
      const { innerText } = previousElementSibling;

      const text = innerText.replace(/^\$ /, "");

      copy(text);

      setLabel("copied!");

      setTimeout(() => setLabel("copy"), 1000);
    },
    [setLabel]
  );

  return (
    <div class="copy" onClick={onClick}>
      ({label})
    </div>
  );
};

const MyCode = ({ title, code, language = "javascript" }) => (
  <MacTerminal
    topbarTitle={title}
    padding="0"
    minHeight="0"
    topbarColor="#444"
    background="#444"
    topbarTitleColor="#CCC"
    className="editor-code"
  >
    <Highlight {...defaultProps} code={code} language={language}>
      {({ className, style, tokens, getLineProps, getTokenProps }) => (
        <pre
          className={className}
          style={{ ...style, padding: "8px", fontSize: "16px" }}
        >
          {tokens.map((line, i) => (
            <div {...getLineProps({ line, key: i })}>
              {line.map((token, key) => (
                <span {...getTokenProps({ token, key })} />
              ))}
            </div>
          ))}
        </pre>
      )}
    </Highlight>
    <Copy />
  </MacTerminal>
);

const MyBrowser = ({ title, children }) => (
  <Browser
    topbarTitle={title}
    padding="0"
    minHeight="0"
    topbarColor="#444"
    background="#444"
    topbarTitleColor="#CCC"
    border="1px solid #333"
    divider="1px solid #333"
  >
    <div>{children}</div>
  </Browser>
);

const MyTerminal = ({ children }) => (
  <Terminal topbarTitle="Terminal" minHeight="0" className="terminal-code">
    <pre>
      <code>
        {React.Children.map(children, (child) => (
          <div class="code-line">
            {child}
            <Copy />
          </div>
        ))}
      </code>
    </pre>
  </Terminal>
);

function App() {
  const [showPrivacyNotice, setShowPrivacyNotice] = useState(
    !document.cookie.includes("privacyOK")
  );
  const onClickPrivacyNotice = useCallback(() => {
    setShowPrivacyNotice(false);
    document.cookie = "privacyOK=true; path=/;";
  }, [setShowPrivacyNotice]);

  return (
    <div className="page">
      <header>
        <div id="sponsor">
          <a href="https://github.com/sponsors/AndersDJohnson">
            <span className="icon" role="img" aria-label="heart">
              ❤️
            </span>{" "}
            sponsor
          </a>
        </div>

        <div id="a">
          <a href="https://forms.gle/ZBCEPsm1eGFey9uS7">your ad here</a>
        </div>

        <h1 id="logo">
          <a className="unstyled" href="https://bookmarkleet.com">
            book
            <br />
            mark
            <br />
            <i>leet</i>
          </a>
        </h1>

        <div id="blurb">
          Write bookmarklets as files in <i>modern</i> JavaScript
          <br />
          and easily sync to your browser.
        </div>
      </header>

      <main>
        <div className="windows">
          <div className="window-blurb">
            &rdquo;Bookmarklets allow you to always keep your common tasks on a web page just a click away.&ldquo;
            &mdash;<a className="unstyled" href="https://support.mozilla.org/en-US/kb/bookmarklets-perform-common-web-page-tasks">Mozilla</a>
          </div>

          <div className="window-blurb">
            Out-of-the-box with Babel's{" "}
            <a
              className="unstyled"
              href="https://babeljs.io/docs/en/babel-preset-env"
            >
              <code>preset-env</code>
            </a>
            . Or{" "}
            <a href="#custom-babel" className="unstyled">
              customize your Babel config
            </a>
            , e.g.,{" "}
            <a href="#typescript" className="unstyled">
              for TypeScript
            </a>{" "}
            or{" "}
            <a href="#jsx-dom" className="unstyled">
              JSX to create DOM
            </a>
            .
          </div>

          <div className="window-blurb">
            First, decide if you want to use <a className="unstyled" href="https://codesandbox.io/s/bookmarkleet-kpqnj?module=%2Fsrc%2Fhi.js">our Codesandbox template</a>,
            or locally.
            For local, open a terminal to create a new project:
          </div>

          <MyTerminal>
            <div>
              $ <span className="command">npm</span> init bookmarkleet{" "}
              <span className="folder">my-bookmarklets</span>
            </div>
          </MyTerminal>

          <div className="window-blurb">
            Check out the example bookmarklet file under "src".
            <br />
            <br />
            You can add your own next to this one.
          </div>

          <MyCode title="src/hi.js" code={"alert('hi from bookmarkleet!');"} />

          <div className="window-blurb">
            Now, back to the terminal, build your bookmarklets:
          </div>

          <MyTerminal>
            <div>
              $ <span className="command">npm</span> start
            </div>
          </MyTerminal>

          <div className="window-blurb">
            This opens your browser, with all your bookmarklets.
            <br />
            <br />
            To install your bookmarklet, drag to your bookmarks bar:
          </div>

          <MyBrowser>
            <img
              className="browser-content"
              alt="Dragging bookmarklets"
              src={dragGif}
            />
          </MyBrowser>

          <div className="window-blurb">
            On any site, in your browser's URL bar, you use bookmark
            autocompletion to run your bookmarklets by typing "bklt", space, and
            then the name of your file.
          </div>

          <MyBrowser>
            <img
              className="browser-content"
              alt="Invoking bookmarklets with URL bar autocomplete"
              src={autocompleteGif}
            />
          </MyBrowser>

          <div className="window-blurb">
            Or sync all at once with <a className="unstyled" href="https://chrome.google.com/webstore/detail/bookmarkleet/ondniecldcajgnppfihecmnkmmeeahpb">our browser extension</a>:
          </div>

          <MyBrowser>
            <img
              className="browser-content"
              alt="Syncing bookmarklets"
              src={syncGif}
            />
          </MyBrowser>

          <div className="window-blurb">
            Or download a bookmarks HTML file for import
            <br />
            into your browser's bookmarks manager.
            <br />
            (Instructions for{" "}
            <a
              href="https://support.google.com/chrome/answer/96816"
              className="unstyled"
            >
              chrome
            </a>{" "}
            <a
              href="https://support.mozilla.org/en-US/kb/export-firefox-bookmarks-to-backup-or-transfer"
              className="unstyled"
            >
              firefox
            </a>
            )
          </div>

          <MyBrowser>
            <img
              className="browser-content"
              alt="Downloading bookmarklets"
              src={downloadGif}
            />
          </MyBrowser>

          <div className="window-blurb">
            Your bookmarklets can also use dependencies from npm:
          </div>

          <MyTerminal>
            <div>
              $ <span className="command">npm</span> add{" "}
              <span className="folder">polished</span>
            </div>
          </MyTerminal>

          <MyCode
            title="src/invert-colors.js"
            code={stripIndent(`
              import { invert } from "polished"

              document.querySelectorAll("*").forEach(el => {
                const { backgroundColor, color } = getComputedStyle(el)
                el.style.backgroundColor = invert(backgroundColor)
                el.style.color = invert(color)
              })
            `).trim()}
          />

          <MyBrowser>
            <img
              className="browser-content"
              alt="Example bookmarklet: invert colors"
              src={invertColorsGif}
            />
          </MyBrowser>

          <div className="window-blurb" id="custom-babel">
            To customize your Babel config, just install presets or plugins, and
            create a "<code>.babelrc.json</code>" file.
          </div>

          <div className="window-blurb" id="typescript">
            For example, to use TypeScript:
          </div>

          <MyTerminal>
            <div>
              $ <span className="command">npm</span> add -D{" "}
              <span className="folder">
                @babel/preset-typescript typescript
              </span>
            </div>
          </MyTerminal>

          <MyCode
            title=".babelrc.json"
            code={stripIndent(`
              {
                "presets": [
                  "@babel/preset-env",
                  "@babel/preset-typescript"
                ],
              }
            `).trim()}
          />

          <MyCode
            title="src/hi.ts"
            language="typescript"
            code={stripIndent(`
              const message: string = 'hi'

              alert(message)
          `).trim()}
          />

          <div className="window-blurb" id="jsx-dom">
            Or, to use JSX to create DOM with{" "}
            <a
              className="unstyled"
              href="https://github.com/vadimdemedes/dom-chef"
            >
              <code>dom-chef</code>
            </a>
            :
          </div>

          <MyTerminal>
            <div>
              $ <span className="command">npm</span> add -D{" "}
              <span className="folder">@babel/plugin-transform-react-jsx</span>
            </div>

            <div>
              $ <span className="command">npm</span> add{" "}
              <span className="folder">dom-chef</span>
            </div>
          </MyTerminal>

          <MyCode
            title=".babelrc.json"
            code={stripIndent(`
              {
                "presets": [
                  "@babel/preset-env",
                ],
                "plugins": [
                  [
                    "@babel/plugin-transform-react-jsx",
                    {
                      "pragma": "h",
                      "pragmaFrag": "DocumentFragment"
                    }
                  ]
                ]
              }
            `).trim()}
          />

          <MyCode
            title="src/button.js"
            code={stripIndent(`
              import { h } from 'dom-chef'

              const handleClick = (e) => {
                e.preventDefault();
                alert("You clicked me!");
              };
              
              const el = (
                <button class="btn" onClick={handleClick}>
                  Click me
                </button>
              );
              
              document.body.prepend(el);
          `).trim()}
          />
        </div>
      </main>

      {showPrivacyNotice && (
        <div className="privacyNotice">
          <div>
            Notice: We use cookies for analytics &amp; ads. See more at our{" "}
            <a href="/privacy" className="unstyled">
              Privacy Policy
            </a>
            .
          </div>

          <button onClick={onClickPrivacyNotice}>OK</button>
        </div>
      )}

      <footer>
        <div>
          <a href="https://forms.gle/ZBCEPsm1eGFey9uS7">contact us</a>
        </div>
        <br />
        <div>copyright &copy; 2020 bookmarkleet</div>
        <br />
        <div id="license" class="legal">
          The software is provided "as is", without warranty of any kind,
          express or implied, including but not limited to the warranties of
          merchantability, fitness for a particular purpose and noninfringement.
          In no event shall the authors or copyright holders be liable for any
          claim, damages or other liability, whether in an action of contract,
          tort or otherwise, arising from, out of or in connection with the
          software or the use or other dealings in the software.
        </div>
        <br />
        <div id="privacy" class="legal">
          Privacy notice: We use cookies for analytics &amp; ads.
          <br />
          <a
            className="unstyled"
            href="https://policies.google.com/technologies/partner-sites"
          >
            See how Google uses data on partner sites
          </a>
          .<br />
          See more at our{" "}
          <a href="/privacy" className="unstyled">
            Privacy Policy
          </a>
          .
        </div>
      </footer>
    </div>
  );
}

export default App;
